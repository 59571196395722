import { useLoader } from "@react-three/fiber";
import { forwardRef, useEffect, useRef } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import * as THREE from "three";

const GltfModel = forwardRef(({ handleChangeMaterial, activeSphere }, ref) => {
  const gltf = useLoader(GLTFLoader, "/latest.gltf"); // Load the GLTF model

  const activeSphereRef = useRef(activeSphere);

  gltf.scene.userData.layer = 0;

  let floor = gltf.scene?.children?.filter(
    (child) => child.material?.name === "Floor"
  );

  console.log(gltf.scene, floor, "floor");

  gltf.scene.traverse((child) => {
    if (child.isMesh) {
      child.userData.clickable = true;
      // child.material.opacity = 1;
      // child.material.metalness = 0.01;
      // child.material.roughness = 0.01;
    }
    if (child?.name === "Ceiling_Inside") {
      child?.material?.color.set(0xd68a8a87);
    }
    if (child?.name === "Walls_Inside") {
      child?.material?.color.set(new THREE.Color(0.5, 0.5, 0.5, 0.63));
    }
  });

  useEffect(() => {
    activeSphereRef.current = activeSphere;
  }, [activeSphere]);

  useEffect(() => {
    window.addEventListener("click", (e) =>
      handleChangeMaterial(e, activeSphereRef.current)
    );

    return () => {
      window.removeEventListener("click", handleChangeMaterial);
    };
  }, []);

  return (
    <group>
      <primitive object={gltf.scene} ref={ref} />
    </group>
  );
});

export default GltfModel;
