import React, { Suspense, useCallback, useRef, useState } from "react";
import * as THREE from "three";
import GltfModel from "./GltfLoader";
import SphereLoad from "./SphereLoad";
import gsap from "gsap";
import { useThree } from "@react-three/fiber";
import { Controls } from "../Controls/Controls";
import { NewHotspots } from "../utils/Spots";
import HotspotLoad from "./HotspotLoad";
import { Materials } from "../utils/Constants";
import BoxLoader from "./BoxLoader";
import { Gui } from "../Gui/Gui";

const Spheres = [
  { url: "/images/pano_5_removed.png", radius: 2.8 },
  { url: "/images/360_Floor-Kahrs_Ash-0001.png", radius: 3 },
];

const MuseumLoad = () => {
  let buttonRef = useRef();
  let museumRef = useRef();
  let sphereRef1 = useRef();
  let sphereRef2 = useRef();
  let sceneRef = useRef();
  let controlsRef = useRef();

  const [spherePosition, setSpherePosition] = useState(null);
  const [activeSphere, setActiveSphere] = useState(0);

  const { camera, scene, gl, invalidate } = useThree();

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  const onClick = () => {
    setActiveSphere((prevActive) => (prevActive === 0 ? 1 : 0));

    const sphereMaterials = [
      sphereRef1.current?.material,
      sphereRef2.current?.material,
    ];

    let hotspotmeshes = scene.children?.[0]?.children?.filter(
      (child) => child.name === "hotspot"
    );

    let museumMaterial = museumRef.current?.children?.filter(
      (child) => child.isMesh === true
    );

    museumMaterial = museumMaterial?.map((child) => {
      return child?.material;
    });

    const tl = gsap.timeline();

    const originalCameraRotation = { ...camera.rotation };
    const originalControlsEnabled = controlsRef.current.enabled;

    controlsRef.current.enabled = false;

    tl.to(camera.rotation, {
      y: camera.rotation.y + Math.PI, // Keep the target's Y position unchanged
      duration: 0.75, // Animation duration in seconds
      ease: "power4.inOut", // Easing function
      onStart: () => {
        let tw = gsap.fromTo(
          museumMaterial,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.37,
            ease: "power4.inOut",
            onComplete: () => {
              museumRef.current?.children?.map((obj) => {
                obj.visible = !obj.visible;
              });
              hotspotmeshes?.map((obj) => {
                obj.visible = !obj.visible;
              });
            },
          }
        );
        tw.play();

        let tween2 = gsap.to(sphereMaterials, {
          opacity: 1,
          duration: 0.37,
          ease: "power4.inOut",
          onComplete: () => {
            sphereRef1.current.visible = !sphereRef1.current.visible;
            sphereRef2.current.visible = !sphereRef2.current.visible;
          },
        });
        tween2.play();
      },
      onComplete: () => {
        // Restore the original camera rotation and controls enabled state
        camera.rotation.copy(originalCameraRotation);
        controlsRef.current.enabled = originalControlsEnabled;
        invalidate(); // Trigger a render update
      },
    });
  };

  const moveToHotspot = (e) => {
    let userData = e.eventObject.userData;

    if (activeSphere === 0) {
      setSpherePosition(userData.position.clone());
      sphereRef1.current.position.copy(userData.position.clone());
      sphereRef2.current.position.copy(userData.position.clone());

      const startPosition = camera.position.clone();
      const targetPosition = userData.position.clone();
      const duration = 1500; // milliseconds
      let startTime = null;
      const startLookAtPosition = camera
        .getWorldDirection(new THREE.Vector3())
        .clone()
        .add(camera.position);

      const targetLookAtPosition = userData.lookAtPosition.clone();

      const animatePosition = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;

        // add animation while navigate
        if (elapsed < duration) {
          const progress = elapsed / duration;
          const newPosition = new THREE.Vector3();
          const newLookAtPosition = new THREE.Vector3();

          newPosition.lerpVectors(startPosition, targetPosition, progress);
          newLookAtPosition.lerpVectors(
            startLookAtPosition,
            targetLookAtPosition,
            progress
          );

          camera.position.copy(newPosition);
          camera.lookAt(newLookAtPosition);

          // Update the controls target to match the camera orientation
          let cameraDirection = targetLookAtPosition
            .clone()
            .sub(newPosition)
            .normalize();

          controlsRef.current.target.copy(
            newPosition.clone().add(cameraDirection)
          );

          requestAnimationFrame(animatePosition);
        } else {
          camera.position.copy(targetPosition);
        }

        gl.render(scene, camera);
      };

      requestAnimationFrame(animatePosition);
    }
  };

  const handleChangeMaterial = useCallback(
    (e, activeSphere) => {
      const rect = document.body.getBoundingClientRect();
      mouse.x = ((e.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children);

      let filteredMaterials = intersects?.filter(
        (ins) =>
          ins?.object?.name?.startsWith("Cube") &&
          ins?.object?.name?.includes("_")
      );

      let filteredHotspots = intersects?.filter(
        (ins) => ins.object.name === "hotspot"
      );

      if (
        filteredHotspots?.length > 0 &&
        filteredHotspots[0]?.object?.name === "hotspot"
      ) {
        return;
      }

      const clickedObject = filteredMaterials?.[0]?.object;

      if (activeSphere === 0) {
        if (
          clickedObject?.name?.startsWith("Cube") &&
          clickedObject?.name?.includes("_")
        ) {
          const clickedMaterial = clickedObject?.material;
          if (clickedMaterial) {
            scene.traverse((child) => {
              if (child.material && child.material?.name) {
                if (
                  child instanceof THREE.Mesh &&
                  child.material?.name == "Floor"
                ) {
                  const newMaterial = clickedMaterial?.clone();

                  newMaterial.name = "Floor";
                  // newMaterial.metalness = 0.5;
                  // newMaterial.roughness = 0.5;
                  newMaterial.needUpdate = true;
                  child.material = newMaterial;
                }
              }
            });

            scene.traverse((child) => {
              if (
                child instanceof THREE.Mesh &&
                child.material?.name == "Floor"
              ) {
                let texture = child?.material?.map;
                if (texture) {
                  texture.wrapS = THREE.MirroredRepeatWrapping;
                  texture.needsUpdate = true;
                }
              }
            });

            if (sphereRef2 && clickedMaterial.name) {
              const textureKey = clickedMaterial?.name;

              let boxMaterials = scene?.children?.[0]?.children;

              let findMesh = boxMaterials?.find(
                (mat) => mat.name === textureKey
              );

              if (findMesh) {
                sphereRef2.current.material.copy(findMesh.material);
              } else {
                let defaultMesh = boxMaterials?.find(
                  (mat) => mat.name === "default"
                );

                sphereRef2.current.material.copy(defaultMesh.material);
              }
            }
          }
        }
      }
    },
    [activeSphere]
  );

  return (
    <group ref={sceneRef}>
      <Controls ref={controlsRef} />

      <ambientLight color={0xffffff} intensity={2.5} />
      <directionalLight
        position={[-3, 10, 15]}
        intensity={0.4}
        color="0xffffff"
      />
      <directionalLight
        position={[-4, 10, -15]}
        intensity={0.3}
        color="0xffffff"
      />

      <Suspense fallback={null}>
        <GltfModel
          ref={museumRef}
          handleChangeMaterial={handleChangeMaterial}
          activeSphere={activeSphere}
        />
        <SphereLoad
          url={Spheres[0].url}
          radius={Spheres[0].radius}
          ref={sphereRef1}
          transparent={true}
          position={spherePosition}
        />
        <SphereLoad
          url={Spheres[1].url}
          radius={Spheres[1].radius}
          ref={sphereRef2}
          position={spherePosition}
        />
        {Object.keys(NewHotspots).map((hots) => {
          return NewHotspots[hots].map((spot, idx) => (
            <HotspotLoad
              key={idx}
              viewPosition={spot.viewPosition}
              lookAtPosition={spot.lookAtPosition}
              hotspotPosition={spot.hotspotPosition}
              ref={controlsRef}
              moveToHotspot={(e) => moveToHotspot(e)}
            />
          ));
        })}
      </Suspense>
      {Materials.map((mat, idx) => {
        return <BoxLoader key={idx} url={mat.path} name={mat.name} />;
      })}
      <Gui toggleLayerVisibility={onClick} />

      {/* <Html position={[-3, 3, 5]}>
        <div
          style={{
            padding: "10px",
            background: "rgba(0, 0, 0, 0.7)",
            borderRadius: "5px",
            transform: "translate(-50%, -50%)", // Center the button
          }}
        >
          <button
            ref={buttonRef}
            onClick={onClick}
            style={{
              background: "blue",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Toggle
          </button>
        </div>
      </Html> */}
    </group>
  );
};

export default MuseumLoad;
