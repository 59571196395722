import { useThree } from "@react-three/fiber";
import React, { forwardRef } from "react";
import * as THREE from "three";

const HotspotLoad = forwardRef(
  (
    { viewPosition, lookAtPosition, hotspotPosition, moveToHotspot },
    controlsRef
  ) => {
    const { camera, scene, gl } = useThree();

    return (
      <mesh
        onClick={moveToHotspot}
        position={hotspotPosition}
        name="hotspot"
        userData={{
          clickable: true,
          position: viewPosition,
          lookAtPosition,
          layer: 0,
        }}
        visible={true}
      >
        <sphereGeometry args={[0.1, 16, 16]} />
        <meshBasicMaterial color="#f9f9f9" />
      </mesh>
    );
  }
);

export default HotspotLoad;
