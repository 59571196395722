export const Constants = {
  ambientLightIntensity: 0.8,
  pointLightIntensity: 1,
  spotLightIntensity: 5,
  spotLightDecay: 1,
  spotLightDistance: 50,
  spotLightPenumbra: 0,
  spotLightConeAngle: Math.PI / 5,
};

export const Materials = [
  { name: "default", path: "/images/360_Floor-Kahrs_Ash-0001.png" },
  { name: "Painting.010", path: "/images/360_Floor-Natural-18-v2.png" },
  { name: "Painting.001", path: "/images/360_Floor-Wheat-0022.png" },
  { name: "Painting.003", path: "/images/360_Floor-Wheat-0022.png" },
];
