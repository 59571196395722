import React from "react";
import PropTypes from "prop-types";
import { GUI } from "dat.gui";

function Gui(props) {
  const { toggleLayerVisibility } = props;
  const guiRef = React.useRef(null); // Initialize with null

  const onceRef = React.useRef(false);
  onceRef.current = true;

  React.useEffect(() => {
    // Initialize the GUI when the component mounts
    guiRef.current = new GUI();
    const gui = guiRef.current;

    gui.domElement.id = "gui";
    gui.domElement.style.position = "absolute";
    gui.domElement.style.top = "10px";
    gui.domElement.style.right = "10px";

    const originalFolder = gui.addFolder("Property");

    originalFolder
      .add(
        {
          toggleLayer0: () => {
            toggleLayerVisibility(0); // Toggle visibility for layer 1
          },
        },
        "toggleLayer0"
      )
      .name("Toggle");

    originalFolder.open();
  }, []); // This effect runs
  return null;
}

export { Gui };
