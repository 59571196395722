import { Box, useTexture } from "@react-three/drei";
import React from "react";
import * as THREE from "three";

const BoxLoader = ({ url, name }) => {
  const texture = useTexture(url, (tex) => {
    tex.mapping = THREE.EquirectangularRefractionMapping;
  });

  return (
    <Box visible={false} name={name}>
      <meshBasicMaterial map={texture} side={THREE.BackSide} />
    </Box>
  );
};

export default BoxLoader;
