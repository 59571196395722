import { useFrame, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useState } from "react";
import * as THREE from "three";

const ArrowKeyControls = forwardRef((props, ref) => {
  const { camera } = useThree();

  const moveSpeed = 0.1;
  const moveVector = new THREE.Vector3();

  const [moveState] = useState({
    forward: false,
    backward: false,
    left: false,
    right: false,
  });

  const onKeyDown = (event) => {
    switch (event.key) {
      case "ArrowUp":
        moveState.forward = true;
        break;
      case "ArrowDown":
        moveState.backward = true;
        break;
      case "ArrowLeft":
        moveState.left = true;
        break;
      case "ArrowRight":
        moveState.right = true;
        break;
    }
  };

  const onKeyUp = (event) => {
    switch (event.key) {
      case "ArrowUp":
        moveState.forward = false;
        break;
      case "ArrowDown":
        moveState.backward = false;
        break;
      case "ArrowLeft":
        moveState.left = false;
        break;
      case "ArrowRight":
        moveState.right = false;
        break;
    }
  };

  const moveWithArrows = (moveVector, moveState, controls, camera) => {
    // movement with up, down, left, right arrow throughout the floor
    moveVector.set(0, 0, 0);

    // get the same camera direction even when turn around 360 degree

    // create quaternion to store the camera's orientation
    const cameraQuaternion = new THREE.Quaternion();

    // to get the camera's global orientation
    camera.getWorldQuaternion(cameraQuaternion);

    // to store the camera's rotation matrix that represents the camera's rotation
    const rotationMatrix = new THREE.Matrix4();
    rotationMatrix.makeRotationFromQuaternion(cameraQuaternion);

    if (moveState.forward) moveVector.add(new THREE.Vector3(0, 0, -1));
    if (moveState.backward) moveVector.add(new THREE.Vector3(0, 0, 1));
    if (moveState.left) moveVector.add(new THREE.Vector3(-1, 0, 0));
    if (moveState.right) moveVector.add(new THREE.Vector3(1, 0, 0));

    // normalize the resulting moveVector
    moveVector.normalize();

    // scale moveVector to speed
    moveVector.multiplyScalar(moveSpeed);

    // create clone and apply it to camera's local rotation matrix
    const moveVectorLocal = moveVector.clone().applyMatrix4(rotationMatrix);

    // get world direction of camera
    const cameraDirection = camera.getWorldDirection(new THREE.Vector3());
    camera.position.add(moveVectorLocal);

    // add target to orbit control to the camera's direction
    controls.target.copy(camera.position.clone().add(cameraDirection));
  };

  // Use the useFrame hook to update the camera position on each frame
  useFrame(() => {
    if (ref) moveWithArrows(moveVector, moveState, ref.current, camera);
  });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return null;
});

export default ArrowKeyControls;
