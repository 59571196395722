import React, { forwardRef } from "react";
import * as THREE from "three";
import { useTexture } from "@react-three/drei";

const SphereLoad = forwardRef(({ url, radius, transparent, position }, ref) => {
  const texture = useTexture(url, (tex) => {
    tex.mapping = THREE.EquirectangularRefractionMapping;
  });

  return (
    <mesh
      // position={newPosition || new THREE.Vector3(-4, 2, 0)}
      ref={ref}
      userData={{ name: "Sphere" }}
      visible={false}
    >
      <sphereGeometry args={[radius, 32, 32]} />
      <meshBasicMaterial
        map={texture}
        side={THREE.BackSide}
        transparent={transparent}
      />
    </mesh>
  );
});

export default SphereLoad;
