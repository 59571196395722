import "./App.css";
import { Canvas } from "@react-three/fiber";
import MuseumLoad from "./Components/Loaders/MuseumLoad";

function App() {
  return (
    <div style={{ position: "relative" }}>
      <Canvas
        style={{ background: "white", height: "100vh" }}
        camera={{
          // fov: 75,
          type: "PerspectiveCamera",
          position: [-10, 1.5, -8],
        }}
      >
        <MuseumLoad />
      </Canvas>
    </div>
  );
}

export default App;
